<template>
  <div>
    <page-title
      :heading="$t('analytics.lang_salesPerInhouseTakeAway')"
      :icon="icon"
      :subheading="$t('analytics.lang_salesPerInhouseTakeAway')"
    ></page-title>

    <div class="app-main__inner">
      <SalesPerInhouseTakeAwayComponent />
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../../Layout/Components/PageTitle";
import SalesPerInhouseTakeAwayComponent from "@/components/analytics/warewanlytics/sales/SalesPerInhouseTakeAwayComponent";

export default {
  name: "index",
  components: { SalesPerInhouseTakeAwayComponent, PageTitle },
  data: () => ({
    icon: "pe-7s-graph1 icon-gradient bg-tempting-azure",
  }),
};
</script>

<style scoped></style>
