<template>
  <v-container fluid>
    <v-card>
      <v-card-title
        :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
        class="card-header card-header-title font-size-lg text-capitalize font-weight-normal"
      >
        {{ $t("analytics.lang_salesPerInhouseTakeAway") }}
      </v-card-title>

      <v-card-text class="mt-6">
        <v-row align="center">
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <BaseDateInput
              v-model="startDate"
              type="date"
              :label="$t('generic.lang_from')"
              dense
              outlined
              hide-details
            />
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <BaseDateInput
              v-model="endDate"
              type="date"
              :label="$t('generic.lang_till')"
              dense
              outlined
              hide-details
            />
          </v-col>

          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <v-btn
              :disabled="this.loading || !startDate || !endDate"
              :loading="this.loading"
              @click="getSalesData"
              block
              class="bg-primary text-light mx-auto"
            >
              <v-icon class="ma-1">remove_red_eye</v-icon>
              {{ this.$t("generic.lang_display") }}
            </v-btn>
          </v-col>

          <v-col cols="12" class="pa-0 ma-0">
            <v-divider class="pa-0 ma-0" />
          </v-col>

          <v-col cols="12" v-if="showTopChart">
            <div class="py-6">
              {{ this.$t("generic.lang_netTakeAwaySales") + ": " }}
              {{ totalNetTakeAway | currency }}
              {{ " / " + this.$t("generic.lang_saleNetInHouse") + ": " }}
              {{ totalNetInHouse | currency }}
            </div>
          </v-col>

          <v-col cols="12" class="mx-auto">
            <v-card v-if="showTopChart">
              <v-card-text>
                <apexchart
                  type="bar"
                  height="700"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>

                <div v-if="loading" class="text-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card v-if="showTable">
              <v-card-text class="pa-0">
                <v-data-table
                  :items="dataToShow"
                  :headers="dataTableHeaders"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                >
                  <template v-slot:item.totalNet="{ item }">
                    {{ item.totalNet | currency }}
                  </template>
                  <template v-slot:item.total="{ item }">
                    {{ item.total | currency }}
                  </template>
                  <template v-slot:item.totalNetInHouse="{ item }">
                    {{ item.totalNetInHouse | currency }}
                  </template>
                  <template v-slot:item.totalNetTakeAway="{ item }">
                    {{ item.totalNetTakeAway | currency }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "@/config";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import { Events } from "@/plugins/events";
import BaseDateInput from "@/components/common/BaseDateInput.vue";

var self = null;

export default {
  name: "SalesPerInhouseTakeAwayComponent",
  components: {
    apexchart: VueApexCharts,
    BaseDateInput,
  },
  mounted() {
    self = this;
  },
  data() {
    return {
      ENDPOINTS,
      startDate: null,
      endDate: null,
      loading: false,
      showTopChart: false,
      totalNetInHouse: 0,
      totalNetTakeAway: 0,
      series: [
        {
          name: this.$t("generic.lang_inHouseSale"),
          data: [],
        },
        {
          name: this.$t("kitchenmonitor.lang_takeAway"),
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
        xaxis: {
          title: {
            text: this.$t("generic.lang_day"),
          },
          categories: [],
        },
        yaxis: {
          title: {
            text: this.$t("accounting.lang_turnover"),
          },
          labels: {
            formatter(val) {
              return self.$options.filters.currency(val);
            },
          },
        },
      },
      sortBy: "day",
      sortDesc: true,
      showTable: false,
      dataToShow: [],
      dataTableHeaders: [
        {
          text: this.$t("generic.lang_day"),
          value: "day",
        },
        {
          text: this.$t("generic.lang_total"),
          value: "total",
        },
        {
          text: this.$t("generic.lang_netTotal"),
          value: "totalNet",
        },
        {
          text: this.$t("generic.lang_saleNetInHouse"),
          value: "totalNetInHouse",
        },
        {
          text: this.$t("generic.lang_netTakeAwaySales"),
          value: "totalNetTakeAway",
        },
        {
          text: this.$t("generic.lang_totalTransactionsInHouse"),
          value: "totalTransactionsInHouse",
        },
        {
          text: this.$t("generic.lang_totalTransactionsTakeAway"),
          value: "totalTransactionsTakeAway",
        },
      ],
    };
  },
  watch: {
    startDate(val) {
      if (
        val != "" &&
        val != null &&
        this.endDate != "" &&
        this.endDate != null &&
        new Date(val).getTime() >= new Date(this.endDate).getTime()
      ) {
        Events.$emit("showSnackbar", {
          color: "error",
          message:
            this.$t("generic.lang_select_date_before") + " : " + this.endDate,
        });
        this.startDate = null;
      }
    },
    endDate(val) {
      if (
        val != "" &&
        val != null &&
        this.startDate != "" &&
        this.startDate != null &&
        new Date(val).getTime() <= new Date(this.startDate).getTime()
      ) {
        Events.$emit("showSnackbar", {
          color: "error",
          message:
            this.$t("generic.lang_select_date_after") + " : " + this.startDate,
        });
        this.endDate = null;
      }
    },
  },
  methods: {
    prepareDataTable(data) {
      this.showTable = false;
      let dataToRender = [],
        row = [];

      data.forEach((el, i) => {
        row = {};

        row["day"] = el["day"];
        row["total"] = Number(el["total"]);
        row["totalNet"] = Number(el["totalNet"]);
        row["totalNetInHouse"] = Number(el["totalNetInHouse"]);
        row["totalNetTakeAway"] = Number(el["totalNetTakeAway"]);
        row["totalTransactionsInHouse"] = Number(
          el["totalTransactionsInHouse"]
        );
        row["totalTransactionsTakeAway"] = Number(
          el["totalTransactionsTakeAway"]
        );

        dataToRender.push(row);
      });

      this.dataToShow = dataToRender;
      this.showTable = true;
    },
    async getSalesData() {
      this.loading = true;
      this.showTopChart = false;
      this.totalNetInHouse = 0;
      this.totalNetTakeAway = 0;
      this.chartOptions.xaxis.categories = [];

      const params = {
        start_date:
          this.startDate == "" || this.startDate == null
            ? null
            : moment(this.startDate).startOf("day").unix(),
        end_date:
          this.endDate == "" || this.endDate == null
            ? null
            : moment(this.endDate).endOf("day").unix(),
      };

      await this.axios
        .post(
          ENDPOINTS.DATATABLES.ANALYTICS.UMSATZANALYSEN.SALESPERINHOUSETAKEAWAY,
          params
        )
        .then((res) => {
          if (res.status == 200) {
            if (
              (Array.isArray(res.data) && !res.data.length) ||
              res.data == null
            ) {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_noDataToDisplay"),
                color: "warning",
              });
              return;
            }

            this.prepareDataTable(res.data);

            for (let i = 0; i < res.data.length; i++) {
              this.totalNetInHouse += res.data[i]["totalNetInHouse"];
              this.totalNetTakeAway += res.data[i]["totalNetTakeAway"];

              this.chartOptions.xaxis.categories.push(res.data[i]["day"]);
              this.series[0]["data"].push(res.data[i]["totalNetInHouse"]);
              this.series[1]["data"].push(res.data[i]["totalNetTakeAway"]);
            }

            this.showTopChart = true;

            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_dataLoading"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.msg || this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
